export async function SendMessage(authToken: string, groupId: string, goalNumber: string, externalNumber: string, text: string) {
    try {
        const response = await fetch(`https://gateway.goalac.org/api/sms/messages/`, {
            method: "POST",
            headers: { "Authorization": `Bearer ${authToken}`,
                        "Content-Type": "application/json" },
            body: JSON.stringify({GroupId: groupId, GoalNumber: goalNumber, ExternalNumber: externalNumber, Text: text})
        })
        return response;
    }
    catch (error) {
        console.log(error);
    }
}

export async function GetMessages(authToken: string, threadId: string) {
    try {
        const response = await fetch(`https://gateway.goalac.org/api/sms/messages/${threadId}`, {
            headers: {"Authorization": `Bearer ${authToken}`}
        })
        return response.json();
    }
    catch (error) {
        console.log(error);
    }
}

export async function GetGroups(authToken: string) {
    try {
        const response = await fetch(`https://gateway.goalac.org/api/sms/groups`, {
            headers: {"Authorization": `Bearer ${authToken}`}
        })
        return response.json();
    }
    catch (error) {
        console.log(error);
    }
}

export async function GetThreads(authToken: string, groupId: string) {
    try {
        const response = await fetch(`https://gateway.goalac.org/api/sms/threads/${groupId}`, {
            headers: {"Authorization": `Bearer ${authToken}`}
        });
        return response.json();
    }
    catch (error) {
        console.log(error);
    }
}

export async function UpdateThread(authToken: string, threadId: string, path: string, value: string){
    try {
        const response = await fetch(`https://gateway.goalac.org/api/sms/threads/${threadId}`, {
            method: "PATCH",
            headers: {"Authorization": `Bearer ${authToken}`,
                        "Content-Type": "application/json"},
            body: JSON.stringify([{op: "replace", path: path, value: value}])
        });
        return response.json();
    }
    catch (error) {
        console.log(error);
    }
}

export async function GetGoalAuthToken(teamsToken: string){
    try {
        const response = await fetch(`https://goal-app-ident-prod-01.azurewebsites.net/AuthenticateWithIDToken`, {        
            method: "GET",
            headers: { "Authorization": `Bearer ${teamsToken}` },
        });
        return response;
    }
    catch (error) {
        console.log(error);
    }
}

export async function GetContacts(authToken: string, searchTerm: string){
    try {
        const response = await fetch(`https://gateway.goalac.org/api/sms/contacts?search=${searchTerm}`, {        
            method: "GET",
            headers: { "Authorization": `Bearer ${authToken}` },
        });
        return response.json();
    }
    catch (error) {
        console.log(error);
    }
}

export async function GetContactDetails(authToken: string, discriminator: string, id: string){
    try {
        const response = await fetch(`https://gateway.goalac.org/api/sms/contactdetails/${discriminator}/${id}`, {        
            method: "GET",
            headers: { "Authorization": `Bearer ${authToken}` },
        });
        return response.json();
    }
    catch (error) {
        console.log(error);
    }
}