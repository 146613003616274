import {
    Avatar,
    Button, 
    Card, CardHeader, CardBody,
    Chat, ChatItem,
    ChatMessage, Dropdown, Dialog, Flex,
    FlexItem, Form, FormInput, FormDropdown, Grid, Image, InfoIcon, Input, List, PersonIcon, PhoneIcon, Provider, RedbangIcon, RetryIcon, Segment, teamsDarkTheme,
    teamsHighContrastTheme,
    teamsTheme, Text, ThemePrepared, EditIcon, ArchiveIcon
} from '@fluentui/react-northstar';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import './App.css';
import * as msTeams from '@microsoft/teams-js';
import * as apiRoutes from './helpers/ApiRoutes';

function GetTheme(themeStr: string | undefined): ThemePrepared {
    themeStr = themeStr || "";

    switch (themeStr) {
        case "dark":
            return teamsDarkTheme;
        case "contrast":
            return teamsHighContrastTheme;
        default:
            return teamsTheme;
    }
}

function PageTitle(title: string, icon?: JSX.Element){
    return (
        <Flex gap="gap.medium" styles={{ paddingLeft: '5px' }}>
            <FlexItem align="center">
                { icon }
            </FlexItem>
            <FlexItem align="center" styles={{ fontSize: "large", fontWeight: "bold" }}>
                <Text content={ title } />
            </FlexItem>
        </Flex>
    )
}

function Drawer(props: any) {
    const drawerStyle = {
        position: "fixed" as "fixed",
        left: 0,
        top: 0,
        hide: {
            transform: "translate3d(-100vw, 0, 0)",
        },
        show: {
            transform: "translate3d(0vw, 0, 0)",
        },
        width: "100vw",
        height: "100vh",
    };

    return (<div style={{position: drawerStyle.position,
                        left: drawerStyle.left,
                        top: drawerStyle.top,
                        width: drawerStyle.width,
                        height: drawerStyle.height,
                        zIndex: 999,
                        transform: props.showDrawer ? drawerStyle.show.transform : drawerStyle.hide.transform}}
            >{props.children}</div >); 

}

function ChatHome() {
    const [localTheme, setLocalTheme] = useState(GetTheme("default"));    
    interface GroupProperties {
        "id": string,
        "name": string,
        "phoneNumber": string
    };
    interface ThreadProperties {
        "key": string,
        "index": number,
        "groupId": string,
        "header": string,
        "externalNumber": string,
        "content": MessageProperties[],
        "contactData": ContactDetailProperties[],
        "isActive": boolean
    };
    interface MessageProperties {
        "key": string,
        "threadId": string,
        "author": string,
        "userId": number,
        "content": MessageContent,
        "timestamp": Date,
        "contentAtStart": boolean,
        "goalNumber": string,
        "externalNumber": string,
        "messageType": string
    };
    interface MessageContent {
        "text": string,
        "serializedMessages": string[]
    };
    interface ThreadListProperties {
        "media": any,
        "index": number,
        "key": string,
        "header": string,
        "headerMedia": string,
        "content": string,
        "isActive": boolean
    }
    interface ContactProperties {
        "gid": string,
        "firstName": string,
        "lastName": string,
        "preferredName": string,
        "phoneNumber": string,
        "discriminator": string
    }
    interface ContactDetailProperties {
        "gid": string,
        "firstName": string,
        "lastName": string,
        "preferredName": string,
        "phoneNumber": string,
        "email": string,
        "podName": string,
        "siteName": string,
        "regionName": string,
        "discriminator": string,
        "associatedContacts": string
    }
    const [threads, setThreads] = useState<ThreadProperties[]>([]);
    const [currentThreadIndex, setCurrentThreadIndex] = useState(0);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [goalAuthToken, setGoalAuthToken] = useState("");
    const [groups, setGroups] = useState<GroupProperties[]>([]);
    const [currentGroup, setCurrentGroup] = useState<GroupProperties>();
    const pageTitle = PageTitle("GOAL SMS App", <PhoneIcon />);
    const [newThread, setNewThread] = useState(false);
    const [newThreadString, setNewThreadString] = useState("");
    const [showThreads, setShowThreads] = useState(true);
    const [contactList, setContactList] = useState<ContactProperties[]>([]);
    const [showContactDetails, setShowContactDetails] = useState(false);
    const [threadList, setThreadList] = useState<ThreadListProperties[]>([]);
    const [editThreadMode, setEditThreadMode] = useState(false);
    const [editedThreadName, setEditedThreadName] = useState("");
    const inputValueRef = useRef("");
    const isFirstRenderRef = useRef(true);
    const threadsLengthRef = useRef(0);
    const isArchiveDialogOpenRef = useRef(false);
    const archiveThreadKeyRef = useRef("");

    /**
    * Renders a Thread Drawer component that displays a list of threads.
    * Allows the user to interact with threads, such as editing the thread name and archiving threads.
    *
    * @param props - The component props.
    * @returns The rendered Thread Drawer component.
    */
    function ThreadDrawer(props: any){
        const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(isArchiveDialogOpenRef.current);

        const handleArchiveButtonClick = useCallback((threadKey: string) => {           
            isArchiveDialogOpenRef.current = true;
            archiveThreadKeyRef.current = threadKey
        }, []);

        const handleArchiveDialogConfirm = useCallback(() => {
            const updatedThreadList = threadList.map(thread => {
                if (thread.key === archiveThreadKeyRef.current){
                    return {...thread, isActive: false};
                }
                return thread;
            });

            setThreadList(updatedThreadList);

            const updatedThreads = threads.map(thread => {
                if (thread.key === archiveThreadKeyRef.current){
                    return {...thread, isActive: false};
                }
                return thread;
            });

            setThreads(updatedThreads);

            updateThread(goalAuthToken, archiveThreadKeyRef.current, "isActive", "false");

            isArchiveDialogOpenRef.current = false;            
            setIsArchiveDialogOpen(isArchiveDialogOpenRef.current);
        }, []);

        const handleArchiveDialogCancel = useCallback(() => {
            isArchiveDialogOpenRef.current = false;            
            setIsArchiveDialogOpen(isArchiveDialogOpenRef.current);
        }, []);

        const handleSelectedIndexChange = useCallback((props:any) => {
            const selectedIndex = props?.selectedIndex!;
            const selectedThread = threadList[selectedIndex];
            const index = threads.findIndex(thread => thread.key === selectedThread.key);
        
            setCurrentThreadIndex(index);
            setNewThread(false);
            
            inputValueRef.current = "";
            isFirstRenderRef.current = true;

            const currentThread = threads[index];

            if (currentThread.contactData === undefined){
                const phoneNumber = FormatPhoneNumber(currentThread.externalNumber, true);

                getContacts(phoneNumber ?? "");

                let currentContact = contactList.find(contact => contact.phoneNumber === phoneNumber);

                if (currentContact){                                                                                
                    currentThread.header = currentContact.firstName + " " + currentContact.lastName;
                }

                getContactDetails(currentContact?.discriminator ?? "", currentContact?.gid ?? "");
            }
        }, []);

        return(
            <Drawer showDrawer={showThreads} >
                <Segment>
                    <Flex styles={{ flexDirection: "row", columnGap: "10px", alignItems: "center"}}>
                        {pageTitle}
                        <Button title="New Chat" content="New" onClick={() => setNewThread(true)} />
                        <Dropdown 
                            inline 
                            items={groups.map((item) => item.name)}
                            value={currentGroup?.name} 
                            placeholder="Select a group" 
                            onChange={(event, data) => {
                                setCurrentGroup(groups.find((element) => element.name === data.value?.toString()));
                                setCurrentThreadIndex(threads.findIndex(thread => thread.groupId === currentGroup?.id));
                                setShowThreads(true);
                            }}
                            onClick={(e: any) => e.stopPropagation()}
                        />
                    </Flex>
                </Segment>
                <Segment styles={{ overflow: "auto" }}>                        
                        {threadList !== undefined ? 
                        <List selectable 
                                defaultSelectedIndex={currentThreadIndex}
                                //Items prop written with ChatGPT 
                                items={threadList.filter(thread => thread.isActive).map((thread, index) => ({                                        
                                    ...thread,
                                    key:index,
                                    truncateContent:true,
                                    header: editThreadMode && thread.key === threadList[currentThreadIndex].key ? (
                                        <Input
                                            ref={(input: any) => {
                                                if (input && editThreadMode && thread.key === threadList[currentThreadIndex].key) {
                                                    input.focus();
                                                }
                                            }}
                                            fluid
                                            defaultValue={editedThreadName}
                                            type="textarea"
                                            onChange={(e: any) => setEditedThreadName(e.target.value.toString())}
                                            onBlur={() => {
                                                const updatedThreads = [...threads];
                                                const threadIndex = threads.findIndex(item => item.key === thread.key);

                                                updatedThreads[threadIndex] = { ...updatedThreads[threadIndex], header: editedThreadName};
                                                
                                                updateThread(goalAuthToken, thread.key, "name", editedThreadName);

                                                setThreads(updatedThreads);
                                                setEditThreadMode(false);
                                                setEditedThreadName("");
                                                setCurrentThreadIndex(threadIndex);
                                            }}
                                            onKeyDown={(e: any) => {
                                                if (e.key === "Enter") {
                                                    e.preventDefault();
                                                    e.target.blur();
                                                    setEditedThreadName("");
                                                }
                                                //This is to keep the space bar from changing which ListItem is selected
                                                else if (e.keyCode === 32) {
                                                    setEditedThreadName(editedThreadName + " ");
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    ) : thread.header,
                                    endMedia: !editThreadMode && (
                                        <div>
                                            <Button title="Edit Name" icon={<EditIcon />} iconOnly onClick={(e: any) => {                                                    
                                                    setEditThreadMode(true);
                                                    setEditedThreadName(thread.header);
                                                    setCurrentThreadIndex(thread.index);
                                                    e.stopPropagation();
                                                }}
                                            />
                                            <Button title="Archive" icon={<ArchiveIcon />} iconOnly onClick={(e) => {handleArchiveButtonClick(thread.key);}} />
                                            <Dialog
                                                open={isArchiveDialogOpen}
                                                cancelButton="Cancel"
                                                confirmButton="Confirm"
                                                header="Warning"
                                                //Content written with ChatGPT
                                                content="Warning: Archiving this chat may lead to the permanent loss of conversation context. 
                                                Once archived, the conversation thread could be unrecoverable. If you foresee needing this information later, consider alternatives like taking screenshots. 
                                                Think carefully before proceeding with archiving, as it could mean losing valuable communication history."
                                                onConfirm={handleArchiveDialogConfirm}
                                                onCancel={handleArchiveDialogCancel}
                                            />                                            
                                        </div>    
                                    )
                                }))}
                                onSelectedIndexChange={(e, props) => {handleSelectedIndexChange(props);}}                                        
                        /> : "Loading..."}                    
                </Segment>
            </Drawer>
        );
    }
    
    /**
    * Renders a Contact Details Drawer component that displays the details of a contact.
    * Shows the contact's information and related details.
    *
    * @param props - The component props.
    * @returns The rendered Contact Details Drawer component.
    */
    function ContactDetailsDrawer(props: any){
        return(
            <Drawer showDrawer={showContactDetails} >
                <Segment>                           
                    <h2>Contact Details</h2>                                             
                </Segment>
                <Segment styles={{ overflow: "auto", height: showContactDetails ? "100vh" : "100%"}}>
                    <Grid columns="1" content={ContactDetails(threads[currentThreadIndex]?.key ?? "")} />
                </Segment>
            </Drawer>
        );
    }

    /**
    * Renders a New Thread Form component for creating a new thread.
    * Allows the user to enter a phone number or search for contacts.
    * Displays a dropdown with matching contact suggestions.
    *
    * @param props - The component props.
    * @returns The rendered New Thread Form component.
    */
    function NewThreadForm(props: any){
        //Written with help from ChatGPT
        const { contactList, getContacts, setNewThreadString, newThreadString} = props;
        const [dropdownItems, setDropdownItems] = useState<string[]>([]);
        const [placeholderText, setPlaceholderText] = useState("Enter a phone number or search here");
        const [inputValue, setInputValue] = useState("");

        const handleSearchQueryChange = useCallback((e: any, {searchQuery}: any) => {            
                if (searchQuery && searchQuery.length >= 3){
                    getContacts(searchQuery);
                    setDropdownItems(contactList.map((contact: any) => `${contact.firstName} ${contact.lastName} ${FormatPhoneNumber(contact.phoneNumber)}`));
                };

                setInputValue(searchQuery);
        }, [getContacts, setDropdownItems, contactList]);

        const handleChange = useCallback((e: any, { value }: any) => {
            setNewThreadString(value?.toString() ?? "");
        }, [setNewThreadString]);

        const handleBlur = useCallback(() => {
            setNewThreadString(inputValue);
        }, [setNewThreadString, inputValue]);

        useEffect(() => {
            setPlaceholderText(newThreadString === "" ? "Enter a phone number or search here" : newThreadString);
          }, [newThreadString]);

        return(
            <Form>
                <FormDropdown
                    id="newThreadString"
                    placeholder={placeholderText}
                    fluid
                    inverted
                    noResultsMessage="No results found"
                    toggleIndicator=""
                    search={true}
                    items={dropdownItems}
                    onSearchQueryChange={handleSearchQueryChange}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    styles={{ placeholder:{fontWeight:"bold" }}}
                />
            </Form>
        );
    }

    /**
    * Renders the Chat Header component.
    * Displays the header section of the chat interface.
    *
    * @param props - The component props.
    * @returns The rendered Chat Header component.
    */
    function ChatHeader(props: any){     
        const [showAssociatedContacts, setShowAssociatedContacts] = useState(false);
        const currentThread = newThread ? {} as ThreadProperties : threads[currentThreadIndex];
        const [currentContact, setCurrentContact] = useState<ContactProperties>(newThread ? (contactList.find((item: any) => newThreadString.includes(item.firstName + " " + item.lastName)) ?? {}) as ContactProperties 
                                                            : (Array.isArray(currentThread?.contactData) ? currentThread?.contactData[0] : {}) as ContactProperties);
        const currentContactDiscriminator = currentContact?.discriminator;
        const [currentContactDetails, setCurrentContactDetails] = useState(currentThread?.contactData ?? []);

        const onCurrentContactChange = (contact: ContactProperties) => {
            setCurrentContact(contact);
        };

        const updateContactDetails = async () => {            
            if (currentThread.contactData === undefined){
                if (newThread) {
                    const result = await apiRoutes.GetContactDetails(goalAuthToken, currentContactDiscriminator, currentContact.gid);
                    const updatedContactDetails: ContactDetailProperties[] = JSON.parse(result.serializedContacts).map((contact: any) => (contact = {
                            firstName: contact.FirstName,
                            lastName: contact.LastName,
                            phoneNumber: contact.PhoneNumber,
                            email: contact.Email,
                            podName: contact.PodName,
                            siteName: contact.SiteName,
                            regionName: contact.RegionName
                        }));

                    setCurrentContactDetails(updatedContactDetails);
                }
                else {
                    getContactDetails(currentContact?.discriminator ?? "", currentContact?.gid ?? "");
                }
            }
        };

        function DisplayContactDetails(contact: ContactDetailProperties) {
            return(    
                <Card fluid>
                    <CardHeader>
                        <Flex column>
                            <Text content={`${contact.firstName ?? ""} ${contact.lastName ?? ""}`} weight="bold"/>
                            <Text content={contact.phoneNumber ? FormatPhoneNumber(contact.phoneNumber) : ""} size="small" />
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Flex column>
                            <Text content={contact.email ? `Email: ${contact.email ?? ""}` : ""} />
                            <Text content={contact.podName ? `Pod Name: ${contact.podName ?? ""}` : ""} />
                            <Text content={contact.siteName ? `Site Name: ${contact.siteName ?? ""}` : ""} />
                            <Text content={contact.regionName ? `Region Name: ${contact.regionName ?? ""}` : ""} />                                                   
                        </Flex>
                    </CardBody>
                </Card>
            );
        }

        return(
            <Segment styles={{ gridColumn: 1, gridRow: 1, top: 0, position: "sticky", zIndex: 1, height: showThreads ? "100vh" : "100%", marginLeft: "5px"}}>
                   <Flex gap="gap.smaller">
                        <Button title="Chats" content="Chats" onClick={() => setShowThreads(!showThreads)} />
                        <Button title="New Chat" content="New Chat" onClick={() => setNewThread(true)} />
                        <Button title="Refresh" icon={<RetryIcon />} iconOnly onClick={() => refresh() } />
                    </Flex>
                    {newThread ?
                        <div style={{ paddingTop: "10px" }}>
                            <Button title="Discard New Chat" content="Discard New Chat" onClick={() => {setNewThread(!newThread); setNewThreadString("");}} />
                            <NewThreadForm contactList={contactList} getContacts={getContacts} setNewThreadString={setNewThreadString} newThreadString={newThreadString}/>
                        </div>
                        : <div>
                            {currentGroup && 
                            <Flex gap="gap.smaller">
                                <h2 style={{display: "inline"}}>{(threads[currentThreadIndex]?.groupId === currentGroup.id) && threads[currentThreadIndex].isActive ? (threads[currentThreadIndex]?.header ?? "") : ""}</h2>
                                <Button title="Contact Details" icon={<InfoIcon />} text iconOnly onClick={() => {
                                                                                                    updateContactDetails();
                                                                                                    setShowContactDetails(true); 
                                                                                                }} />
                            </Flex>
                            }                            
                        </div>
                    }
                    {(currentContactDiscriminator) &&
                        <div>
                            <Button title="Guardian(s)/Student(s)" 
                                    content={currentContactDiscriminator?.toLowerCase() === "student" ? "Guardian(s)" : currentContactDiscriminator?.toLowerCase() === "guardian" ? "Student(s)" : "" } 
                                    onClick={() => {
                                        updateContactDetails();
                                        setShowAssociatedContacts(!showAssociatedContacts);
                                    }}
                            />
                            {showAssociatedContacts &&
                                <List selectable items={currentContactDetails.map(item => DisplayContactDetails(item))} 
                                    onSelectedIndexChange={(e, props) => {
                                        const currentSelectedIndex = props?.selectedIndex!;
                                        const selectedContact = currentContactDetails[currentSelectedIndex];
                                        const selectedContactNameAndNumber = `${selectedContact.firstName} ${selectedContact.lastName} ${FormatPhoneNumber(selectedContact.phoneNumber)}`;

                                        setNewThreadString(selectedContactNameAndNumber);
                                        
                                        getContacts(selectedContact.firstName + " " + selectedContact.lastName);

                                        const selectedContactSearchResult = contactList.find((item: any) => selectedContactNameAndNumber.includes(item.firstName + " " + item.lastName))

                                        if (selectedContactSearchResult){
                                            onCurrentContactChange(selectedContactSearchResult);
                                        }
                                    }
                                }/>
                            }                    
                        </div>
                    }
                    <div>
                        <Dropdown inline 
                                items={groups.map((item) => item.name)}
                                value={currentGroup?.name} 
                                placeholder="Select a group" 
                                onChange={(event, data) => {
                                    setCurrentGroup(groups.find((element) => element.name === data.value?.toString()));
                                    setCurrentThreadIndex(threads.findIndex(thread => thread.groupId === currentGroup?.id));
                                    setShowThreads(true);
                                }}/>
                    </div>
                </Segment>
                
        );
    }

    /**
    * Renders the Send Message Form component.
    * Displays the input form for sending messages in the chat interface.
    *
    * @param props - The component props.
    * @returns The rendered Send Message Form component.
    */
    function SendMessageForm(props: any){
        const {threads, currentThreadIndex, newThread, currentGroup, contactList, 
                SendMessage, setNewThread, setNewThreadString, setCurrentThreadIndex, getContacts, getContactDetails,
                    onSendMessage} = props;     
        const currentThread = threads[currentThreadIndex];
        const threadsLength = threads.length;
        const threadIndex = currentThreadIndex;        
        const [inputValue, setInputValue] = useState(inputValueRef.current);
        const [messageSent, setMessageSent] = useState(false);

        const sendMessageToNewThread = useCallback(() => {
            SendMessage(currentGroup?.id ?? "", currentGroup?.phoneNumber ?? "", newThreadString, inputValue);
            setNewThread(false);
            setNewThreadString("");
            setCurrentThreadIndex(threads.findIndex((thread: any) => FormatPhoneNumber(thread.externalNumber, true) === FormatPhoneNumber(newThreadString, true)) === -1 ? threadsLength : threadIndex);

            getContacts(newThreadString);

            const currentContact = contactList.find((contact: any) => contact.phoneNumber === newThreadString);
            
            if (currentContact){
                getContactDetails(currentContact?.discriminator ?? "", currentContact?.gid ?? "");
            }
        }, [currentGroup, contactList, SendMessage, setNewThread, setNewThreadString, setCurrentThreadIndex, getContacts, getContactDetails, inputValue,
            threadsLength, threads, threadIndex]);

        const handleFormSubmit = useCallback((e: any, props: any) => {
                if (!newThread) {                                    
                    SendMessage(currentGroup?.id ?? "", currentGroup?.phoneNumber ?? "", currentThread.externalNumber, inputValue);                    
                }
                else {
                    sendMessageToNewThread();
                }
                setInputValue("");
                inputValueRef.current = "";
                onSendMessage();
                setMessageSent(true);
        }, [newThread, currentGroup, currentThread, SendMessage, inputValue, onSendMessage, sendMessageToNewThread]);        

        const onInputValueChange = useCallback((value: any) => {
            setInputValue(value);
            inputValueRef.current = value;
        }, []);

        useEffect(() => {
            if (messageSent){
                const timeout = setTimeout(() => {
                    setMessageSent(false);
                }, 1000);
            
                return () => clearTimeout(timeout);
            }
        }, [messageSent]);

        return(
            <Form styles={{ justifyContent: "flex-end", width: "100%" }} onSubmit={handleFormSubmit}>
                <div style={{ display: "flex", alignItems: "center", width: "100%"}}>
                    <FormInput
                        name="inputValue"
                        id="inputValue"
                        placeholder="Type a new message"
                        successIndicator={"Message Sent"}
                        showSuccessIndicator={messageSent}                    
                        inverted
                        fluid
                        value={inputValue}
                        onChange={(e, props ) => onInputValueChange(props?.value?.toString() ?? "")}
                        maxLength={1500}
                        type="text"
                        styles={{ flex: 1, marginRight: "5px"}}
                    />
                    <Button title="Send" content="Send" styles={{ verticalAlign: "middle" }} type="submit" />
                </div>
            </Form>
        );
    }

    /**
    * Renders the Chat Window component.
    * Displays the chat interface with messages and a message input form.
    *
    * @param props - The component props.
    * @returns The rendered Chat Window component.
    */
    function ChatWindow(props: any){
        const {currentGroup, threads, currentThreadIndex, newThread, GetThread} = props;
        const chatItems = GetThread(currentGroup?.id ?? "", threads[currentThreadIndex]?.key);
        const scrollRef = useRef<HTMLDivElement>(null);
        let currentThreadContentLength = (threads[currentThreadIndex]?.content?.length ?? 0); 

        const onSendMessage = useCallback(() => {
            refresh();
        }, []);

        //This is used to scroll to the bottom on the first render or when chatItems changes
        useEffect(() => {
            if (isFirstRenderRef.current || (currentThreadContentLength > threadsLengthRef.current)) {
                isFirstRenderRef.current = false;                
                threadsLengthRef.current = (currentThreadContentLength ?? 0);                
                scrollRef.current?.scrollIntoView({behavior: "smooth", block: "end"});
            }
            
        }, [currentThreadContentLength]);

        return (
            <Chat styles={{ overflow: "auto", paddingTop: "30px", height: "100%" }} >
                {!newThread && chatItems}
                <div ref={scrollRef} />
                <SendMessageForm threads={threads}
                    currentThreadIndex={currentThreadIndex} 
                    newThread={newThread}
                    currentGroup={currentGroup} 
                    contactList={contactList} 
                    SendMessage={SendMessage} 
                    setNewThread={setNewThread} 
                    setNewThreadString={setNewThreadString}
                    setCurrentThreadIndex={setCurrentThreadIndex}
                    getContacts={getContacts}
                    getContactDetails={getContactDetails}
                    onSendMessage={onSendMessage}
                />
            </Chat>
        );
    }

    /**
    * Authenticates the user and retrieves the necessary tokens.
    * Uses the MS Teams authentication API to acquire the authentication token,
    * which is then used to get a GOAL token.
    * Updates the goalAuthToken state and sets the userLoggedIn state.
    */
    const authenticate = useCallback(() => {
        var authTokenRequest = {
            //If the teams token is successfully acquired, it is then used to get a GOAL token
            successCallback: (result: any) => {
                apiRoutes.GetGoalAuthToken(result).then(
                    (value) => {
                        value?.text().then((finalValue: string) => setGoalAuthToken(finalValue));
                        setUserLoggedIn(true);
                    },
                    (error) => {
                        console.log("Error getting token: " + error);
                    });                
            },
            failureCallback: (error: any) => {
                console.log("Error getting token: " + error);
            }
        };

        msTeams.authentication.getAuthToken(authTokenRequest);
    }, []);

    /**
    * Retrieves the list of threads for a specific group.
    * Filters the threads based on the provided groupId and maps them to ThreadListProperties.
    * ThreadListProperties includes information about the last message in each thread.
    * The threads are sorted based on the timestamp of the last message in descending order.
    * Updates the threadList state with the formatted and sorted thread list.
    * 
    * @param groupId - The id of the specific group.
    */
    const getThreadList = useCallback((groupId: string) => {
        const threadList = threads.filter(thread => thread.groupId === groupId)
            .map(thread => {
                let lastMessage = thread.content.sort((a, b) => a.timestamp.valueOf() - b.timestamp.valueOf()).at(-1);
                if (lastMessage?.content !== undefined){
                    return {
                        "media": <Avatar icon={<PersonIcon />} />,
                        "index": thread.index,
                        "key": thread.key,
                        "header": thread.header,
                        "headerMedia": lastMessage?.timestamp.toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" }),
                        "content": lastMessage?.content.text,
                        "isActive": thread.isActive
                }}
                return {};
        }).sort((a, b) => new Date(b?.headerMedia!).valueOf() - new Date(a?.headerMedia!).valueOf());
        
        threadList.forEach((thread, index) => { if (thread) {thread.index = index}});        

        setThreadList(threadList as ThreadListProperties[]);
    }, [threads]);

    /**
    * Retrieves a specific thread based on the provided groupId and threadKey.
    * Filters the threads array to find the matching thread if it is not archived.
    * Maps the content of the thread to ChatItems representing individual messages.
    * Each ChatItem includes details such as the author, content, timestamp, and position.
    * Sorts the ChatItems based on the timestamp of each message in ascending order.
    * Returns the resulting thread as an array of ChatItems.
    * 
    * @param groupId - The id of the group to which the specific thread belongs.
    * @param threadKey - The key of the specific thread.
    * 
    * @returns - The resulting thread as an array of ChatItems.
    */
    function GetThread(groupId: string, threadKey: string) {
        const thread = threads.find(thread => threadKey === thread.key && groupId === thread.groupId && thread.isActive)?.content
            .map(messageItem =>  
                <ChatItem
                    gutter={<Avatar icon={<PersonIcon />} />}
                    key={messageItem.key}
                    message={
                        <ChatMessage
                            badge={messageItem && messageItem.author && messageItem.author.toLowerCase() === "system" ? { icon: <RedbangIcon />} : null}
                            variables={messageItem && messageItem.author && messageItem.author.toLowerCase() === "system" ? {isImportant: true} : {isImportant: false}}
                            author={messageItem.author}
                            content={
                                <>
                                    {messageItem.content.text}
                                    {Array.isArray(messageItem.content.serializedMessages) &&
                                        messageItem.content.serializedMessages.map((url: string) =>
                                            <Image fluid src={url} key={url} styles={window.innerWidth >= 768 ? {maxWidth:"60vw", maxHeight:"60vh"} : {maxWidth:"100%", maxHeight:"100%"}}/>
                                        )
                                    }
                                </>
                            }
                            timestamp={messageItem.timestamp.toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric"})}
                            mine={messageItem.contentAtStart ? false : true}
                            details={messageItem.contentAtStart ? "" : messageItem.author}
                        />
                    }                    
                    contentPosition={messageItem.contentAtStart ? "start" : "end"}                                                                    
                    styles={window.innerWidth < 768 ? {width:"90vw"} : {width: "fitContent"}}
                /> 
        ).sort((a, b) => new Date(a.props.message.props.timestamp).valueOf() - new Date(b.props.message.props.timestamp).valueOf());
        
        return thread;
    }    

    /**
    * Retrieves contacts from the server based on the provided search term.
    * Uses the "GetContacts" API route with the "goalAuthToken" and "searchTerm" parameters.
    * Maps the result to a new contact list format.
    * Updates the existing contact list with the new contacts.
    * Sets the updated contact list using the "setContactList" function.
    * Handles errors by logging them to the console.
    * 
    * @param searchTerm - The provided search term.
    */
    const getContacts = useCallback(async (searchTerm: string) => {
        try{
            const result = await apiRoutes.GetContacts(goalAuthToken, searchTerm);
            const newContactList = result.map((item: any) => (item =
                {
                "gid": item.id,
                "firstName": item.firstName,
                "lastName": item.lastName,
                "preferredName": item.preferredName,
                "phoneNumber": item.phoneNumber,
                "discriminator": item.discriminator
            }));
            const updatedContactList = contactList;

            newContactList.forEach((contact: any)  => {
                if (!updatedContactList.some(item => item.gid === contact.gid)){
                    updatedContactList.push(contact);
                }
            });

            setContactList(updatedContactList);
        } catch (error: any){
            console.log("Error getting contacts: " + error);
        }
    }, [goalAuthToken, contactList]);

    /**
    * Retrieves contact details from the server based on the provided discriminator and ID.
    * Uses the "GetContactDetails" API route with the "goalAuthToken", "discriminator", and "id" parameters.
    * Finds the index of the thread associated with the result's phone number in the current thread list.
    * Updates the contact data of the corresponding thread with the new contact details.
    * Updates the threads list with the updated thread.
    * Handles errors by logging them to the console.
    * 
    * @param discriminator - The discriminator for the contact (i.e. Student or Guardian).
    * @param id - The id of the contact.
    */
    const getContactDetails = useCallback(async (discriminator: string, id: string) => {
        try{
            const result = await apiRoutes.GetContactDetails(goalAuthToken, discriminator, id);
            const newThreadIndex = threads.findIndex(thread => FormatPhoneNumber(thread.externalNumber, true) === result.phoneNumber);
            const newThread = threads[newThreadIndex];

            if (newThread !== undefined && newThreadIndex !== -1) {
                if (Array.isArray(result)){
                    newThread.contactData = 
                        result.map((contact: any) => (contact = {
                                gid: contact.id,
                                firstName: contact.firstName,
                                lastName: contact.lastName,
                                preferredName: contact.preferredName,
                                phoneNumber: contact.phoneNumber,
                                email: contact.email,
                                podName: contact.podName,
                                siteName: contact.siteName,
                                regionName: contact.regionName,
                                discriminator: contact.discriminator,
                                associatedContacts: contact.serializedContacts
                    }));
                }
                else {
                    newThread.contactData = [{
                                gid: result.id,
                                firstName: result.firstName,
                                lastName: result.lastName,
                                preferredName: result.preferredName,
                                phoneNumber: result.phoneNumber,
                                email: result.email,
                                podName: result.podName,
                                siteName: result.siteName,
                                regionName: result.regionName,
                                discriminator: result.discriminator,
                                associatedContacts: result.serializedContacts
                    }]
                }           
                const updatedThreads = [...threads];
                
                updatedThreads.splice(newThreadIndex, 1, newThread);
                setThreads(updatedThreads);
            }
        } catch (error: any){
            console.log("Error getting contact details: " + error);
        }
    }, [goalAuthToken, threads]);
    
    /**
    * Retrieves contact details for a specific thread from the threads list.
    * Finds the thread with the provided "threadId" in the threads list.
    * Extracts the contact data from the found thread.
    * Renders the contact details as a series of Text components using the extracted contact data.
    * Returns the rendered contact details.
    * 
    * @param threadId - The specific thread from the threads list.
    * 
    * @returns - The rendered contact details.
    */
    function ContactDetails(threadId: string){
        const currentContactThread = threads.find(thread => threadId === thread.key);        
        const contactDetails = currentContactThread?.contactData;

        return contactDetails?.map((contactDetail) => (
                <Card>
                    <CardHeader>
                        <Flex column>
                            <Text content={`${contactDetail.firstName ?? ""} ${contactDetail.lastName ?? ""}`} weight="bold"/>
                            <Text content={contactDetail.phoneNumber ? FormatPhoneNumber(contactDetail.phoneNumber) : ""} size="small" />
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Flex column>
                            <Text content={`Gid: ${contactDetail.gid ?? ""}`} />
                            <Text content={`Preferred Name: ${contactDetail.preferredName ?? ""}`} />
                            <Text content={`Email: ${contactDetail.email ?? ""}`} />
                            <Text content={`Pod Name: ${contactDetail.podName ?? ""}`} />
                            <Text content={`Site Name: ${contactDetail.siteName ?? ""}`} />
                            <Text content={`Region Name: ${contactDetail.regionName ?? ""}`} />                                                   
                        </Flex>
                    </CardBody>
                </Card>
          )) ?? <Card>
                    <CardHeader>
                        <Text content={`Phone Number: ${currentContactThread?.externalNumber ?? ""}`} />
                    </CardHeader>
                </Card> 
          ;
    }

    /**
    * Sends a message by calling the API route to send a message.
    * Formats the "externalNumber" using "FormatPhoneNumber" utility function if available, otherwise uses the original "externalNumber".
    * Calls the API route to send the message with the provided parameters: "groupId", "goalNumber", "externalNumber", and "text".
    * 
    * @param groupId - The id of the group sending the message.
    * @param goalNumber - The goal phone number to send the message.
    * @param externalNumber - The external number to send the message to.
    * @param text - The text of the message.
    */
    function SendMessage(groupId: string, goalNumber: string, externalNumber: string, text: string) {
        const fromGoalNumber = goalNumber;
        const toExternalNumber = FormatPhoneNumber(externalNumber, true) ?? externalNumber;

        apiRoutes.SendMessage(goalAuthToken, groupId, fromGoalNumber, toExternalNumber, text);
    }

    /**
    * Formats a phone number by removing non-digits and applying a specific format.
    * If "removeNonDigits" is set to true, the function removes all non-digit characters from the phone number.
    * If "removeNonDigits" is set to false or not provided, the function applies a standard format to the phone number.
    * The formatted phone number is returned.
    * 
    * @param phoneNumber - The phone number to format.
    * @param removeNonDigits - Removes non-digits if set to true.
    * 
    * @returns - The formatted phone number.
    */
    function FormatPhoneNumber(phoneNumber: string | undefined, removeNonDigits: boolean = false) {
        if (!phoneNumber) {
            return phoneNumber;
        }

        let cleanPhoneNumber = phoneNumber.replace(/\D/g, "");
        
        //This removes the country code of 1 if it is present
        if (cleanPhoneNumber.at(0) === "1") {
            cleanPhoneNumber = cleanPhoneNumber.slice(1);
        }

        if (removeNonDigits) {
                return cleanPhoneNumber?.replace(/\D/g, "");
        };        

        const cleanPhoneNumberLength = cleanPhoneNumber.length;

        if (cleanPhoneNumberLength < 4) {
            return cleanPhoneNumber;
        }

        if (cleanPhoneNumberLength < 7) {
            return "(" + cleanPhoneNumber.slice(0, 3) + ") " + cleanPhoneNumber.slice(3);
        }

        return "(" + cleanPhoneNumber.slice(0, 3) + ") " + cleanPhoneNumber.slice(3, 6) + "-" + cleanPhoneNumber.slice(6, 10);
    }

    msTeams.initialize();
    msTeams.registerOnThemeChangeHandler(() => msTeams.getContext(context => setLocalTheme(GetTheme(context.theme?.toString()))));
    msTeams.getContext((context) => {
        setLocalTheme(GetTheme(context.theme?.toString()));
    });   

    /**
    * Retrieves groups using the provided goalAuthToken.
    * If the goalAuthToken is not empty, the function makes an API call to fetch the groups associated with the token.
    * The retrieved groups are then mapped to a new format and stored in the state. 
    */
    //Written with help from ChatGPT
    const getGroups = useCallback(async () => {
        try {
            if (goalAuthToken !== ""){
                const result = await apiRoutes.GetGroups(goalAuthToken);             
                const newGroups = result.map((group: any) => ({
                            "id": group.id,  
                            "name": group.name,  
                            "phoneNumber": group.phoneNumber 
                    }));
                    
                setGroups(newGroups);
            };
        } catch (error: any) { 
            console.log("Error getting groups: " + error);
        }
    }, [goalAuthToken]); 
    
    /**
    * Retrieves threads for each group using the provided goalAuthToken and groups.
    * The function makes API calls to fetch threads for each group in parallel using Promise.all.
    * The retrieved threads are mapped to ThreadProperties and stored in the state.
    */
    //Written with help from ChatGPT
    const getThreads = useCallback(async () => {
        try {
            const newThreadsPromises = groups.map(async (group: any) => {
                const result = await apiRoutes.GetThreads(goalAuthToken, group.id);                
                const newThreads: ThreadProperties[] = result.map((thread: any, threadIndex: number) => ({
                    "key": thread.threadId,
                    "index": threadIndex,
                    "groupId": thread.groupId,
                    "header" : thread.name === "" ? FormatPhoneNumber(thread.externalNumber) : thread.name,
                    "externalNumber": FormatPhoneNumber(thread.externalNumber) ?? thread.externalNumber,
                    "content": [],
                    "isActive": thread.isActive
                }));
                
                return newThreads;
            });

            const newThreads = await Promise.all(newThreadsPromises);            
            const flattenedThreads = newThreads.flat();

            setThreads(flattenedThreads);
        } catch (error: any){
            console.log("Error getting threads: " + error);
        }
    }, [goalAuthToken, groups]);

    /**
     * Updates a specified thread with new values
     * @param authToken The GOAL authorization token
     * @param threadId The threadId of the thread to be updated
     * @param path The specific field to update
     * @param value The new updated value
     */
    async function updateThread(authToken: string, threadId: string, path: string, value: string){
        try {
            await apiRoutes.UpdateThread(authToken, threadId, path, value);
        } catch (error: any){
            console.log("Error updating thread: " + error);
        }
    }

    /**
    * Retrieves messages for each thread using the provided goalAuthToken and threads.
    * The function makes API calls to fetch messages for each thread in parallel using Promise.all.
    * The retrieved messages are mapped to MessageProperties and stored in the corresponding thread object.
    * Finally, the updated threads are stored in the state.
    */
    //Written with help from ChatGPT
    const getMessages = useCallback(async () => {
        try {
            const updateThreadContentPromises = threads.map(async thread => {
                    const result = await apiRoutes.GetMessages(goalAuthToken, thread.key);
                    const newMessages = result.map((message: any) => ({
                        "key": message.messageId,
                        "threadId": message.threadId,
                        "author": message.staffName === "" ? thread.header : message.staffName,
                        "userId": message.staffId,
                        "content": {
                            text: message.text,
                            serializedMessages: message.serializedMessages ? JSON.parse(message.serializedMessages).map((item: any) => item.toString()) : [], 
                        },
                        "timestamp": new Date(message.timestamp),
                        "goalNumber": message.goalNumber,
                        "externalNumber": message.externalNumber,
                        "messageType": message.type,
                        "contentAtStart": message.type ? true : false
                    }));
                    
                    thread.content = newMessages;
                });

            await Promise.all(updateThreadContentPromises);

            setThreads(updatedThreads => updatedThreads);
        } catch (error: any) {
            console.log("Error getting messages: " + error);
        }
    }, [goalAuthToken, threads]);

    /**
     * This triggers the retrieval of threads and messages without causing unnecessary re-renders
     */
    const refresh = useCallback(() => {
        getThreads();
        getMessages();
    }, [getThreads, getMessages]);

    useEffect(() => {
        authenticate();
    }, [authenticate]);

    useEffect(() => {
        getGroups();
    }, [getGroups]);

    useEffect(() => {
        getThreads();
    }, [getThreads]);

    useEffect(() => {
        getMessages();
    }, [getMessages]);

    useEffect(() => {
        //This makes sure that message data is loaded before the threadList is set
        const initializeGroups = async () => {
            await getMessages();            
            getThreadList(currentGroup?.id ?? "");
        };

        initializeGroups();
    }, [getThreadList, getMessages, currentGroup]);

    useEffect(() => {
        setCurrentGroup(groups[0]);
    }, [groups]);

    /**
     * This useEffect hook uses the setInterval method to refresh the page every 60 seconds (Note: number shown is in milliseconds)
     */
    useEffect(() => {
        const interval = setInterval(() => {
            refresh();
        }, 60000);

        return () => clearInterval(interval);
    }, [refresh]);

    return (
        <Provider theme={localTheme}>
            {userLoggedIn ?
            <Grid columns="10fr" rows="1fr 16fr" styles={{ height: "100vh", overflow: "auto" }} onClick={() => {if (showThreads && !editThreadMode) { 
                                                                                                                    setShowThreads(false);
                                                                                                                }
                                                                                                                if (showContactDetails) setShowContactDetails(!showContactDetails); } }>
                <ThreadDrawer />
                <ContactDetailsDrawer />
                <ChatHeader />
                <Segment styles={{ gridColumn: 1, gridRow: 2 }}>
                    <ChatWindow 
                        currentGroup={currentGroup} 
                        threads={threads}
                        currentThreadIndex={currentThreadIndex}
                        newThread={newThread}
                        GetThread={GetThread}  
                    />
                </Segment>
            </Grid>
        : "You are not authorized to view this resource."}
        </Provider>
    );
}

export default ChatHome;
